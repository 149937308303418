@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap");

@font-face {
  font-family: "Joane";
  src: url("/assets/font/Joane-Regular.woff2") format("woff2"),
    url("/assets/font/Joane-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
