.intro {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  &__background {
    background-image: url(https://user-images.githubusercontent.com/41680436/156932363-adcfb396-0d3f-4733-bbb6-a104300ff0eb.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__logo {
    width: 3rem;
    height: 6rem;
    background-image: url(/assets/icons/logo.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    margin-top: 4.5rem;
  }

  &::after {
    content: "";
    width: 3rem;
    height: 6rem;
    margin-bottom: 4.5rem;
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4rem;
    padding: 0 var(--margin);
    box-sizing: border-box;
    width: 100%;

    &__titles {
      position: relative;
      width: 100%;
      max-width: 100%;

      @media (--laptop) {
        max-width: var(--columns-4);
      }

      h1 {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
