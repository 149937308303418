:root {
  --gutter: 3rem;
  --margin: 3.2rem;
  --column: calc((100vw - (3 * var(--gutter)) - (2 * var(--margin))) / 4);
  --full-column: calc(var(--column) + var(--gutter));
  --vrem: 1.1160714286vh;

  --columns-1: calc((1 * var(--column)));
  --columns-2: calc((2 * var(--column)) + (1 * var(--gutter)));
  --columns-3: calc((3 * var(--column)) + (2 * var(--gutter)));
  --columns-4: calc((4 * var(--column)) + (3 * var(--gutter)));
  --columns-5: calc((5 * var(--column)) + (4 * var(--gutter)));
  --columns-6: calc((6 * var(--column)) + (5 * var(--gutter)));
  --columns-7: calc((7 * var(--column)) + (6 * var(--gutter)));
  --columns-8: calc((8 * var(--column)) + (7 * var(--gutter)));
  --columns-9: calc((9 * var(--column)) + (8 * var(--gutter)));
  --columns-10: calc((10 * var(--column)) + (9 * var(--gutter)));
  --columns-11: calc((11 * var(--column)) + (10 * var(--gutter)));
  --columns-12: calc((12 * var(--column)) + (11 * var(--gutter)));

  --transition-ease--out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --transition-ease--out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --transition-ease--out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --transition-ease--in-quint: cubic-bezier(0.64, 0, 0.78, 0);
  --transition-ease--in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
  --transition-ease--in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);

  --transition-duration--fastest: 0.2s;
  --transition-duration--faster: 0.4s;
  --transition-duration--more-fast: 0.6s;
  --transition-duration--fast: 0.8s;
  --transition-duration--normal: 1s;
  --transition-duration--slow: 1.2s;
  --transition-duration--more-slow: 1.4s;
  --transition-duration--slower: 1.6s;
  --transition-duration--slowest: 1.8s;

  @media (--tablet) {
    --column: calc((100vw - (7 * var(--gutter)) - (2 * var(--margin))) / 8);
  }

  @media (--laptop) {
    --vrem: 0.9765625vh;
    --gutter: 4rem;
    --column: 7rem;
    --vertical-padding: calc(7.2 * var(--vrem));
    --margin: calc((100vw - (11 * var(--gutter)) - (12 * var(--column))) / 2);
  }
}
