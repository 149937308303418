main {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;

  .content {
    box-sizing: border-box;
    padding: 0 var(--margin) var(--margin);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    white-space: normal;

    @media (--laptop) {
      padding: 0 var(--margin) calc(6 * var(--vrem));
    }
  }
}

.minervetta-scrollbar {
  position: absolute;
  bottom: var(--margin);
  left: var(--margin);
  height: 1px;
  width: calc(100% - (var(--margin) * 2));
  background-color: rgba(46, 49, 144, 0.15);
  opacity: 1;
  display: none;

  @media (--laptop) {
    bottom: 32px;
  }

  &_thumb {
    display: block;
    width: 18rem;
    max-width: 18rem;
    height: 1px;
    background-color: var(--blue);
  }

  .no-scrollbar & {
    display: none;
  }
}
