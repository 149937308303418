.page-card {
  width: var(--columns-4);
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  row-gap: calc(3.2 * var(--vrem));

  @media (--laptop) {
    row-gap: calc(2.4 * var(--vrem));
  }

  &--coming-soon {
    pointer-events: none;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;

    &--border {
      border: 10px solid var(--red);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.2rem;

    @media (--laptop) {
      column-gap: 1.6rem;
    }

    &::after {
      content: "coming soon";
      white-space: nowrap;
      font-size: calc(var(--vrem) * 1.2);
      line-height: calc(var(--vrem) * 1.2);
      font-family: var(--font-body);
      padding: calc(var(--vrem) * 0.4) calc(var(--vrem) * 0.8);
      border: 1px solid var(--white);
      border-radius: calc(var(--vrem) * 1);
      display: none;

      .page-card--coming-soon & {
        display: block;
      }
    }
  }
}
