.columns {
  --red: rgba(255, 0, 0, 0.1);
  margin: 0 var(--margin);
  width: calc(100vw - (2 * var(--margin)));
  height: 100vh;
  background: repeating-linear-gradient(
    to right,
    var(--red),
    var(--red) var(--column),
    transparent var(--column),
    transparent var(--full-column)
  );
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  display: none;
  z-index: 999;

  &--visible {
    display: block;
  }
}
