.horizontal-page-image {
  width: var(--columns-4);
  height: 100%;
  position: relative;
  overflow: hidden;

  &--medium {
    width: var(--columns-4);

    @media (--laptop) {
      width: var(--columns-6);
    }
  }

  &--large {
    width: var(--columns-6);

    @media (--laptop) {
      width: var(--columns-8);
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;

      &[data-scroll-speed] {
        width: calc(100% + var(--columns-2));
        left: calc((var(--columns-1) + var(--gutter)) * -1);
      }
    }
  }
}
