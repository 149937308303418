.rooms__images {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.6rem;
  height: 100%;

  @media (--laptop) {
    column-gap: 4rem;
  }

  &__room {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.6rem;
    position: relative;

    @media (--laptop) {
      column-gap: 4rem;
    }

    & + &::before {
      content: "";
      width: 4rem;
      height: 100%;
      background-color: var(--blue);
      margin: 0 calc(var(--gutter) / 2);

      @media (--laptop) {
        width: 8rem;
      }
    }

    &:nth-child(odd)::before {
      background-color: var(--red);
    }
  }
}
