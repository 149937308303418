:root {
  --white: #ffffff;
  --blue: #2e3190;
  --red: #bf272d;
  --yellow: #ffef00;
  --light-blue: #00abd6;
}

.white {
  color: var(--white);
}

.blue {
  color: var(--blue);
}

.red {
  color: var(--red);
}
