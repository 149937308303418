:root {
  --font-body: "Manrope", sans-serif;
  --font-heading: "Joane";
}

.font-body {
  font-family: var(--font-body);
}

.font-heading {
  font-family: var(--font-heading);
}

h1 {
  font-size: calc(1.8 * var(--vrem));

  @media (--laptop) {
    font-size: calc(3.6 * var(--vrem));
  }
}

h2 {
  font-size: calc(1.8 * var(--vrem));
}

.p0 {
  font-size: calc(2.4 * var(--vrem));

  @media (--laptop) {
    font-size: calc(3.2 * var(--vrem));
  }
}

.p1 {
  font-size: calc(2.4 * var(--vrem));

  @media (--laptop) {
    font-size: calc(2.8 * var(--vrem));
  }
}

.p2 {
  font-size: calc(1.4 * var(--vrem));

  @media (--laptop) {
    font-size: calc(2 * var(--vrem));

    &--static {
      font-size: calc(1.4 * var(--vrem));
    }
  }
}

.p3 {
  font-size: calc(1.2 * var(--vrem));

  @media (--laptop) {
    font-size: calc(1.8 * var(--vrem));
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}
