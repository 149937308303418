.page-transitioner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 997;
  box-sizing: border-box;
  pointer-events: none;
  color: var(--red);

  &__bands {
    display: grid;
    grid-template-rows: 100vh;
    grid-auto-flow: column;

    &__band {
      background-color: currentColor;
      will-change: transform;
      transition: transform var(--transition-duration--slow)
        var(--transition-ease--out-expo);
      transform: scaleX(0);
      transform-origin: center right;

      .page-transitioner--show & {
        transform: scaleX(1);
        transform-origin: center left;
      }

      &--white {
        background-color: var(--white);
      }

      &--desktop {
        display: none;
        transition: none;

        @media (--laptop) {
          display: block;

          transition: transform var(--transition-duration--slow)
            var(--transition-ease--out-expo);
        }
      }
    }
  }
}
