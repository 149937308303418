.text-reveal {
  display: block;
  position: relative;

  .button & {
    pointer-events: none;
  }

  &__text {
    display: flex;

    &--reveal {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__letter-wrapper {
      perspective: 600px;

      &__letter {
        display: block;
        //transform-style: preserve-3d;
        transform-origin: center top;
        will-change: opacity, transform;
        opacity: 0;
        transform: translateY(100%) rotateX(80deg);
        transition: var(--transition-duration--fast)
          var(--transition-ease--out-quint);
        transition-property: opacity, transform;
        white-space: pre;

        .text-reveal--visible & {
          opacity: 1;
          transform: translateY(0) rotateX(0deg);
        }

        .text-reveal:hover &,
        .button:hover &,
        .page-card:hover &,
        .hamburger:hover & {
          @media (--laptop) {
            opacity: 0;
            transform: rotateX(-80deg) translateY(-100%);
          }
        }

        .text-reveal--revealed.text-reveal--visible & {
          opacity: 0;
          transform: rotateX(-80deg) translateY(-100%);
        }

        .text-reveal__text--reveal & {
          opacity: 0;
          transform: translateY(100%) rotateX(-80deg);

          .text-reveal:hover &,
          .button:hover &,
          .page-card:hover &,
          .hamburger:hover & {
            @media (--laptop) {
              opacity: 1;
              transform: translateY(0) rotateX(0deg);
            }
          }

          .text-reveal--revealed.text-reveal--visible & {
            opacity: 1;
            transform: translateY(0) rotateX(0deg);
          }
        }
      }
    }
  }
}
