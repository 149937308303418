.maison__intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  width: var(--columns-3);
  box-sizing: border-box;
  row-gap: 4rem;

  @media (--laptop) {
    row-gap: 5rem;
    width: var(--columns-4);
  }
}
