.lunch-aperitif__images {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: calc(var(--margin) / 2);
  height: 100%;

  @media (--laptop) {
    column-gap: var(--gutter);
  }
}
