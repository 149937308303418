.lunch-aperitif__intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  width: var(--columns-4);
  box-sizing: border-box;

  @media (--laptop) {
  }

  &__title {
    margin-bottom: 4rem;

    @media (--laptop) {
      margin-bottom: 5rem;
    }
  }

  &__text {
    margin-bottom: auto;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    row-gap: calc(2 * var(--vrem));
    margin-top: calc(4.8 * var(--vrem));

    @media (--laptop) {
      max-width: calc(4 * var(--full-column));
    }

    &__contact {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      > span {
        min-width: var(--full-column);

        @media (--laptop) {
          width: var(--columns-2);
          flex-shrink: 0;
        }
      }
    }
  }

  > .button {
    margin-bottom: 0;
    margin-top: auto;
  }
}
