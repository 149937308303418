.story__intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  width: var(--columns-4);
  box-sizing: border-box;

  @media (--laptop) {
    row-gap: 5rem;
  }

  &__text {
    @media (--laptop) {
      width: var(--columns-3);
    }
  }
}
