.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 997;
  opacity: 1;
  will-change: opacity;
  transition: opacity 1.6s var(--transition-ease--in-out-quint);

  .loaded & {
    opacity: 0;
    transition-delay: var(--transition-duration--slow);
  }

  &__content {
    width: calc(var(--columns-2) + var(--gutter) * 2);
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: calc(2.8 * var(--vrem));
    z-index: 1;

    @media (--laptop) {
      position: static;
      transform: none;
      top: 0;
      left: 0;
      width: calc(var(--full-column) * 6 + var(--margin));
      flex-shrink: 0;
      row-gap: calc(5.6 * var(--vrem));
    }

    &__logo {
      width: 100%;
      height: calc(10.6 * var(--vrem));
      background-image: url("/assets/icons/logo--complete.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @media (--laptop) {
        width: 32.2rem;
        height: calc(17.6 * var(--vrem));
      }
    }

    &__bands {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 2.8rem;

      @media (--laptop) {
        width: 32.2rem;
        column-gap: 4.8rem;
      }

      &__band {
        background-color: var(--red);
        height: calc(1.8 * var(--vrem));
        min-width: 4.4rem;
        will-change: transform;
        transform: scaleX(0);
        transform-origin: center left;
        transition: transform var(--transition-duration--slow)
          var(--transition-ease--in-out-quint);

        @media (--laptop) {
          height: calc(3 * var(--vrem));
          min-width: 7.2rem;
        }

        .loaded & {
          transition: transform var(--transition-duration--slow)
            var(--transition-ease--in-out-quint);
          transform-origin: center right;
          transform: scaleX(0) !important;
        }

        &:last-of-type {
          flex-grow: 1;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;

    &__image {
      opacity: 1 !important;
    }
  }
}
