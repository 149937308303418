.language-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: var(--gutter);
  pointer-events: none;
  overflow: hidden;

  @media (--laptop) {
    column-gap: 8rem;
  }

  .language-selector--visible & {
    pointer-events: all;
  }

  &__item {
    z-index: 2;
    opacity: 1;
    transition: opacity var(--transition-duration--fast)
      var(--transition-ease--out-quint);

    .selector--hovered & {
      opacity: 0.6;
    }

    &--current {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    &--current,
    .selector--hovered & {
      pointer-events: initial;
    }
  }
}
