.page__content {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: calc(var(--margin) / 2);

  @media (--laptop) {
    column-gap: var(--gutter);
  }
}
