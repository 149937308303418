.button {
  padding: 0.6rem 1rem;
  border: 1px solid currentColor;
  transition: all var(--transition-duration--fast)
    var(--transition-ease--out-quint);

  @media (--laptop) {
    padding: 1rem 3.2rem;
  }

  &:hover {
    @media (--laptop) {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  &--large {
    padding: 1rem 4rem;
  }

  &--rounded {
    padding: 0.8rem 1.6rem;
    border-radius: 10rem;
  }

  &--arrow {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0;
    border-radius: 3.5rem;
    border: 1px solid var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.9s var(--transition-ease--out-quint);

    @media (--laptop) {
      width: calc(5.6 * var(--vrem));
      height: calc(5.6 * var(--vrem));
      border-radius: calc(2.8 * var(--vrem));
    }

    &:hover {
      @media (--laptop) {
        background-color: var(--blue);
        color: var(--white);
      }
    }

    &.button--disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    &__wrapper {
      width: 1.4rem;
      height: 0.7rem;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media (--laptop) {
        width: calc(2.7 * var(--vrem));
        height: calc(1.5 * var(--vrem));
      }

      .button--arrow-right & {
        flex-direction: row-reverse;
      }

      svg {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        will-change: transform;
        transition: all 0.7s var(--transition-ease--out-quint) 0.2s,
          color 0.9s var(--transition-ease--out-quint);

        .button--arrow:hover & {
          @media (--laptop) {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.3s var(--transition-ease--in-quint) 0s,
              color 0.9s var(--transition-ease--out-quint);
          }
        }

        .button--arrow-right:hover & {
          @media (--laptop) {
            transform: translate3d(100%, 0, 0);
          }
        }

        &:last-child {
          opacity: 0;
          transition: all 0.3s var(--transition-ease--in-quint),
            color 0.9s var(--transition-ease--out-quint);

          .button--arrow:hover & {
            @media (--laptop) {
              opacity: 1;
              transition: all 0.7s var(--transition-ease--out-quint) 0.2s,
                color 0.9s var(--transition-ease--out-quint);
            }
          }
        }

        path {
          stroke: currentColor;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
