.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.6rem;
  color: var(--black);

  @media (--laptop) {
    align-items: flex-start;
  }

  &--light {
    color: var(--white);
  }

  &__line,
  &__line::before,
  &::before,
  &::after {
    content: "";
    width: 1.75rem;
    height: 0.1rem;
    background-color: currentColor;
    transition: transform var(--transition-duration--fast)
      var(--transition-ease--out-quint);
    will-change: transform;
  }

  &__line {
    position: relative;

    .nav-visible & {
      transform: rotate(45deg);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;

      .nav-visible & {
        transform: rotate(-90deg);
      }
    }
  }

  &__line,
  &__line::before {
    .nav-visible & {
      transition-delay: var(--transition-duration--fastest);
    }
  }

  &::before {
    .nav-visible & {
      transform: translateY(calc(0.6rem + 100%));
    }
  }

  &::after {
    .nav-visible & {
      transform: translateY(calc(-0.6rem - 100%));
    }
  }

  &::before,
  &::after {
    opacity: 1;
    transition: transform var(--transition-duration--fast)
        var(--transition-ease--out-quint) var(--transition-duration--fastest),
      opacity 0s var(--transition-duration--fastest);

    .nav-visible & {
      opacity: 0;
      transition: transform var(--transition-duration--fast)
          var(--transition-ease--out-quint) 0s,
        opacity 0s var(--transition-duration--fastest);
    }
  }

  &__text {
    display: none;

    @media (--laptop) {
      display: block;
      position: absolute;
      left: 100%;
    }

    .nav & {
      display: none;
    }
  }
}
