.animated-word {
  display: flex;

  &__letter-wrapper {
    perspective: 600px;
  }

  &__letter {
    display: block;
    transform-style: preserve-3d;
    transform-origin: center top;
    transform: translateY(100%) rotateX(-80deg);
    opacity: 0;
    will-change: transform, opacity;
    transition: var(--transition-duration--normal)
      var(--transition-ease--out-quint);
    transition-property: opacity, transform;
    white-space: pre;

    .animated-word--show-down & {
      transform: translateY(-100%) rotateX(-80deg);
    }

    .animated-word--visible & {
      opacity: 1;
      transform: translateY(0) rotateX(0deg);
    }
  }
}
