.header {
  width: 100%;
  padding: calc(4 * var(--vrem)) var(--margin) calc(6 * var(--vrem));
  margin-bottom: calc(4 * var(--vrem));
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  @media (--laptop) {
    padding: calc(6 * var(--vrem)) var(--margin) calc(10 * var(--vrem));
    margin-bottom: calc(6 * var(--vrem));
  }

  &__bands {
    width: calc(100% - (2 * var(--margin)));
    height: calc(3.2 * var(--vrem));
    position: absolute;
    bottom: 0;
    left: var(--margin);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: var(--gutter);

    @media (--laptop) {
      height: calc(4 * var(--vrem));
      column-gap: var(--column);
    }

    &__band {
      background-color: var(--red);
      height: 100%;
      min-width: var(--column);
      will-change: transform;
      transition: transform var(--transition-duration--slow)
        var(--transition-ease--in-out-quint);

      @media (--laptop) {
        min-width: var(--full-column);
      }

      &:first-of-type::after,
      &:last-of-type::before {
        content: "";
        height: 100%;
        width: calc(var(--gutter) / 2);
        position: absolute;
        top: 0;
        background-color: var(--red);
        transform: scaleX(0);
        transition: transform var(--transition-duration--faster)
          var(--transition-ease--out-quint);

        @media (--laptop) {
          display: none;
        }

        .language-selector--visible & {
          transform: scaleX(1);
        }
      }

      &:first-of-type {
        &::after {
          transform-origin: center left;
          left: 100%;
        }
      }

      &:last-of-type {
        flex-grow: 1;

        &::before {
          transform-origin: center right;
          right: 100%;
        }
      }
    }
  }

  &__left {
    width: var(--column);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media (--laptop) {
      width: var(--columns-3);
      order: 1;
      flex-shrink: 0;
      justify-content: flex-start;
    }

    .hamburger {
      position: relative;
    }
  }

  &__hamburger {
    @media (--laptop) {
      width: var(--column);
    }
  }

  &__languages-toggle {
    width: var(--gutter);

    @media (--laptop) {
      width: auto;
      cursor: pointer;
      order: 3;
      margin-right: var(--gutter);
      margin-left: auto;
    }
  }

  &__logo {
    height: 6rem;
    width: var(--columns-2);
    margin-left: 0;
    margin-right: auto;

    @media (--laptop) {
      height: calc(5.2 * var(--vrem));
      order: 2;
      width: var(--columns-6);
      margin: 0 var(--gutter);
      background-position: center left;
      flex-shrink: 0;
    }

    a {
      display: block;
      height: 100%;
      width: 3rem;
      margin: 0 auto;
      background-image: url("/assets/icons/logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @media (--laptop) {
        width: 100%;
        margin-left: 0;
        background-image: url("/assets/icons/logo--expanded.svg");
      }
    }
  }

  &__booking {
    order: 4;
    display: flex;
    justify-content: flex-end;
    width: var(--full-column);

    @media (--laptop) {
      width: var(--columns-2);
      flex-shrink: 0;
    }
  }

  &__languages {
    width: 100%;
    height: calc(3.2 * var(--vrem));
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 var(--margin);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @media (--laptop) {
      height: calc(4 * var(--vrem));
    }
  }
}
