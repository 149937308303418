.image {
  position: relative;
  background-image: url("/assets/icons/image_placeholder.png");
  background-position: center;
  background-size: contain;

  .image-placeholder--0 & {
    background-image: url("/assets/icons/image_placeholder.png");
  }

  .image-placeholder--1 & {
    background-image: url("/assets/icons/image_placeholder_1.png");
  }

  .image-placeholder--2 & {
    background-image: url("/assets/icons/image_placeholder_2.png");
  }

  .image-placeholder--3 & {
    background-image: url("/assets/icons/image_placeholder_3.png");
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    transition: opacity var(--transition-duration--fast)
      var(--transition-ease--out-quint);
    opacity: 0;
    z-index: 2;

    .image--loaded & {
      opacity: 1;
    }
  }
}
