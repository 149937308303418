.contacts__map {
  width: var(--columns-4);
  height: 100%;
  position: relative;

  @media (--laptop) {
    width: var(--columns-12);
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
