.nav {
  width: 100%;
  height: calc(100% - var(--header-height));
  max-width: 100%;
  background-color: var(--white);
  box-sizing: border-box;
  overflow: hidden;

  position: fixed;
  top: var(--header-height);
  left: 0;
  z-index: 996;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);

  pointer-events: none;
  will-change: clip-path;
  transition: clip-path var(--transition-duration--normal)
    var(--transition-ease--in-out-quint) var(--transition-duration--faster);

  .nav-visible & {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    pointer-events: initial;
    transition-delay: 0s;
  }

  .resizing & {
    transition: none !important;
  }

  @media (--laptop) {
    height: 100vh;
    top: 0;
    flex-direction: row;
    align-items: center;
    column-gap: var(--gutter);
    justify-content: space-between;
    padding-left: var(--margin);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);

    .nav-visible & {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  &__left {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: calc(3 * var(--vrem)) 0;

    @media (--laptop) {
      width: var(--columns-6);
      padding: calc(6 * var(--vrem)) 0;
      flex-shrink: 0;
      justify-content: space-between;
    }

    &__bottom {
      flex-shrink: 0;

      @media (--laptop) {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: calc(5.2 * var(--vrem));
      }
    }
  }

  &__logo {
    background-image: url(/assets/icons/logo--expanded.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: calc(5.2 * var(--vrem));
    flex-shrink: 0;
    display: none;
    will-change: opacity;
    transition: opacity var(--transition-duration--faster) linear;
    opacity: 0;

    .nav-visible & {
      opacity: 1;
      transition-delay: var(--transition-duration--fastest);
    }

    @media (--laptop) {
      display: block;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &__social {
      width: calc(2.4 * var(--vrem));
      height: calc(2.4 * var(--vrem));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      will-change: opacity;
      transition: opacity var(--transition-duration--fast)
        var(--transition-ease--out-quint);
      opacity: 0;

      .nav-visible & {
        opacity: 1;
        transition-delay: var(--transition-duration--faster);
      }

      &--instagram {
        background-image: url(/assets/icons/instagram.svg);
      }
    }
  }

  &__menu {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: calc(3.2 * var(--vrem));

    @media (--laptop) {
      height: 100%;
      row-gap: calc(5.2 * var(--vrem));
    }

    &__item {
      position: relative;

      a {
        display: block;
      }

      &--coming-soon {
        &::before {
          display: block;
          content: "soon";
          color: var(--red);
          white-space: nowrap;
          font-size: calc(var(--vrem) * 1.2);
          line-height: calc(var(--vrem) * 1.2);
          font-family: var(--font-body);
          padding: calc(var(--vrem) * 0.4) calc(var(--vrem) * 0.8);
          border: 1px solid var(--red);
          border-radius: calc(var(--vrem) * 1);
          position: absolute;
          left: calc(100% + 0.8rem);
          bottom: 50%;
          opacity: 0;
          transition: opacity var(--transition-duration--fastest) linear;

          @media (--laptop) {
            content: "coming soon";
          }

          .nav-visible & {
            opacity: 1;
            transition-delay: var(--transition-duration--fast);
          }
        }
      }
    }
  }

  &__bands {
    width: 100%;
    height: calc(var(--vrem) * 15);
    flex-shrink: 0;
    display: none;
    grid-auto-flow: row;

    @media (--laptop) {
      height: 100%;
      display: grid;
    }

    &__band {
      background-color: var(--red);
      will-change: transform;
      transition: transform var(--transition-duration--fast)
        var(--transition-ease--out-quint);
      transform: scaleY(1);

      &--desktop {
        display: none;

        @media (--laptop) {
          display: block;
        }
      }

      &--white {
        background-color: var(--white);
      }
    }
  }

  &__close {
    display: none !important;
    position: absolute;
    left: var(--margin);
    top: calc((var(--header-height) - (4 * var(--vrem))) / 2);
    transform: translateY(-50%);
    cursor: pointer;

    @media (--laptop) {
      display: flex !important;
      top: calc((var(--vrem) * 6) + ((5.2 * var(--vrem)) / 2));
    }
  }
}
