.contacts {
  .page__content {
    flex-direction: column;
    align-items: flex-start;
    row-gap: calc(1.6 * var(--vrem));

    @media (--laptop) {
      flex-direction: column-reverse;
      align-items: center;
      row-gap: calc(5 * var(--vrem));
    }
  }
}
