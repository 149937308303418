.pages-cards {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.6rem;
  height: 100%;

  @media (--laptop) {
    column-gap: 4rem;
  }
}
