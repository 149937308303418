html {
  font-size: 2.5641025641vw;

  @media (--tablet) {
    font-size: calc(2.5641025641vw * 0.5);
  }

  @media (--laptop) {
    font-size: 0.6944444444vw;
  }
}

body {
  background-color: var(--white);
  color: var(--blue);
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;

  &.red-band::before {
    content: "";
    width: 100%;
    height: calc(32 * var(--vrem));
    background-color: var(--blue);
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &.page-loading,
  &.page-loading * {
    pointer-events: none;
  }
}

html,
body {
  height: calc(var(--dvh, 1vh) * 100);
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
