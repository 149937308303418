.contacts__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: calc(2 * var(--vrem));
  width: var(--columns-4);
  box-sizing: border-box;

  @media (--laptop) {
    width: 100%;
    height: calc(11.4 * var(--vrem));
    row-gap: 0.2rem;
    column-gap: calc(2 * var(--column) + 3 * var(--gutter));
    flex-wrap: wrap;
    flex-shrink: 0;
  }

  &__contact {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @media (--laptop) {
      column-gap: var(--gutter);
    }

    > span {
      min-width: var(--full-column);

      @media (--laptop) {
        width: var(--columns-2);
        flex-shrink: 0;
      }
    }

    > a {
      @media (--laptop) {
        width: var(--columns-3);
      }
    }
  }
}
